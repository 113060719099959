import React from 'react';
import { arrayOf, shape, func } from 'prop-types';
import CompatsWidget from '../block';

import { COMPATS_WIDGET_STICKY, COMPATS_WIDGET_BLOCK } from '../compats.constants';

const namespace = 'ui-pdp-compats';
const CompatsInfo = ({ components, onSubmitForm, onRemove }) => {
  const hasCompats =
    components &&
    components.find(component => component.type === COMPATS_WIDGET_BLOCK || component.type === COMPATS_WIDGET_STICKY);

  return (
    <>
      {hasCompats && (
        <CompatsWidget
          components={components}
          onSubmitForm={onSubmitForm}
          onRemove={onRemove}
          namespace={namespace}
          modifier="mobile"
        />
      )}
    </>
  );
};

CompatsInfo.propTypes = {
  components: arrayOf(shape({})),
  onRemove: func,
  onSubmitForm: func,
};

CompatsInfo.defaultProps = {
  onRemove: null,
  onSubmitForm: null,
  components: null,
};

export default CompatsInfo;
